/*=========================================================*/
/*=================== Main page animation =================*/
/*=========================================================*/

.change-my-role {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.animation {
  height: 48px;
  overflow: hidden;
  margin-left: 10px;
}

.animation > div > div {
  padding: 0px 10px;
  height: 48px;
  margin-bottom: 48px;
  display: inline-block;
}

.animation div:first-child {
  animation: text-changing 8s infinite;
  animation-delay: 2s;
}

.first div {
  background-color: var(--color-yellow);
}
.second div {
  background-color: var(--color-lilac);
}
.third div {
  background-color: var(--color-green);
}

@keyframes text-changing {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: 0;
  }
  20% {
    margin-top: -96px;
  }
  30% {
    margin-top: -96px;
  }
  40% {
    margin-top: -192px;
  }
  60% {
    margin-top: -192px;
  }
  70% {
    margin-top: -96px;
  }
  80% {
    margin-top: -96px;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .change-my-role {
    height: calc(100vw * 48 / 767);
  }

  .animation {
    height: calc(100vw * 48 / 767);
  }

  .animation > div > div {
    height: calc(100vw * 48 / 767);
    margin-bottom: calc(100vw * 48 / 767);
  }

  @keyframes text-changing {
    0% {
      margin-top: 0;
    }
    10% {
      margin-top: 0;
    }
    20% {
      margin-top: calc(100vw * -96 / 767);
    }
    30% {
      margin-top: calc(100vw * -96 / 767);
    }
    40% {
      margin-top: calc(100vw * -192 / 767);
    }
    60% {
      margin-top: calc(100vw * -192 / 767);
    }
    70% {
      margin-top: calc(100vw * -96 / 767);
    }
    80% {
      margin-top: calc(100vw * -96 / 767);
    }
    90% {
      margin-top: 0;
    }
    100% {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .change-my-role {
    height: calc(100vw * 48 / 1000);
  }

  .animation {
    height: calc(100vw * 48 / 1000);
  }

  .animation > div > div {
    height: calc(100vw * 48 / 1000);
    margin-bottom: calc(100vw * 48 / 1000);
  }

  @keyframes text-changing {
    0% {
      margin-top: 0;
    }
    10% {
      margin-top: 0;
    }
    20% {
      margin-top: calc(100vw * -96 / 1000);
    }
    30% {
      margin-top: calc(100vw * -96 / 1000);
    }
    40% {
      margin-top: calc(100vw * -192 / 1000);
    }
    60% {
      margin-top: calc(100vw * -192 / 1000);
    }
    70% {
      margin-top: calc(100vw * -96 / 1000);
    }
    80% {
      margin-top: calc(100vw * -96 / 1000);
    }
    90% {
      margin-top: 0;
    }
    100% {
      margin-top: 0;
    }
  }
}

/*=========================================================*/
/*====================== Ticker ===========================*/
/*=========================================================*/

.ticker {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.ticker-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transform: translate(100%, 0);
  position: absolute;
  animation: ticker-animation 20s infinite linear forwards;
  top: 0;
  right: 0;
}

.ticker p {
  font-family: "Russo One", sans-serif;
  font-size: 52px;
  -webkit-text-stroke: 1px var(--color-black);
  color: transparent;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .ticker p {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  .ticker p {
    font-size: 26px;
  }

  .ticker-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transform: translate(100%, 0);
    position: absolute;
    animation: ticker-animation 10s infinite linear forwards;
    top: 0;
    right: 0;
  }
}

.ticker:hover p {
  -webkit-text-stroke: 1px #fff;
  color: transparent;
  transition: color 0.5s linear;
}

.first-part {
  animation: ticker-animation 20s infinite linear forwards;
}

.second-part {
  animation: 20s ticker-animation 10s infinite linear forwards;
}

@keyframes ticker-animation {
  0% {
    transform: translate(100%, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

/*=========================================================*/
/*=================== Projects cards ======================*/
/*=========================================================*/

.project-card {
  position: relative;
  perspective: 1000px;
  cursor: pointer;
}

.project-card .project-card-content,
.project-card .project-card-back-content {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.project-card .project-card-content {
  z-index: 2;
  backface-visibility: hidden;

  transform: rotateY(0deg);
}

.project-card .project-card-back-content {
  z-index: 1;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.project-card.flipped .project-card-content {
  transform: rotateY(180deg);
}

.project-card.flipped .project-card-back-content {
  transform: rotateY(360deg);
}

/*=========================================================*/
/*=================== icon animations ======================*/
/*=========================================================*/

.contact-icons li:nth-child(1) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 0.5s;
}

.contact-icons li:nth-child(2) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 1s;
}

.contact-icons li:nth-child(3) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 1.5s;
}

.contact-icons li:nth-child(4) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 2s;
}

.contact-icons li:nth-child(5) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 2.5s;
}

.contact-icons li:nth-child(6) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 3s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.delay {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 2s;
}

/*=========================================================*/
/*================== Header appearence ====================*/
/*=========================================================*/

.heading-xl {
  overflow: hidden;
}

.animated-header {
  display: inline-block;
  margin: 0;
  padding: 0;
  animation: appearence 2s forwards;
}

@keyframes appearence {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
