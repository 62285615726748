.change-my-role {
  height: 48px;
  justify-content: left;
  align-items: center;
  display: flex;
}

.animation {
  height: 48px;
  margin-left: 10px;
  overflow: hidden;
}

.animation > div > div {
  height: 48px;
  margin-bottom: 48px;
  padding: 0 10px;
  display: inline-block;
}

.animation div:first-child {
  animation: 8s 2s infinite text-changing;
}

.first div {
  background-color: var(--color-yellow);
}

.second div {
  background-color: var(--color-lilac);
}

.third div {
  background-color: var(--color-green);
}

@keyframes text-changing {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  20% {
    margin-top: -96px;
  }

  30% {
    margin-top: -96px;
  }

  40% {
    margin-top: -192px;
  }

  60% {
    margin-top: -192px;
  }

  70% {
    margin-top: -96px;
  }

  80% {
    margin-top: -96px;
  }

  90% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .change-my-role, .animation {
    height: 6.25815vw;
  }

  .animation > div > div {
    height: 6.25815vw;
    margin-bottom: 6.25815vw;
  }

  @keyframes text-changing {
    0% {
      margin-top: 0;
    }

    10% {
      margin-top: 0;
    }

    20% {
      margin-top: -12.5163vw;
    }

    30% {
      margin-top: -12.5163vw;
    }

    40% {
      margin-top: -25.0326vw;
    }

    60% {
      margin-top: -25.0326vw;
    }

    70% {
      margin-top: -12.5163vw;
    }

    80% {
      margin-top: -12.5163vw;
    }

    90% {
      margin-top: 0;
    }

    100% {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .change-my-role, .animation {
    height: 4.8vw;
  }

  .animation > div > div {
    height: 4.8vw;
    margin-bottom: 4.8vw;
  }

  @keyframes text-changing {
    0% {
      margin-top: 0;
    }

    10% {
      margin-top: 0;
    }

    20% {
      margin-top: -9.6vw;
    }

    30% {
      margin-top: -9.6vw;
    }

    40% {
      margin-top: -19.2vw;
    }

    60% {
      margin-top: -19.2vw;
    }

    70% {
      margin-top: -9.6vw;
    }

    80% {
      margin-top: -9.6vw;
    }

    90% {
      margin-top: 0;
    }

    100% {
      margin-top: 0;
    }
  }
}

.ticker {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ticker-text {
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  animation: 20s linear infinite forwards ticker-animation;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%);
}

.ticker p {
  -webkit-text-stroke: 1px var(--color-black);
  color: #0000;
  font-family: Russo One, sans-serif;
  font-size: 52px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .ticker p {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  .ticker p {
    font-size: 26px;
  }

  .ticker-text {
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    animation: 10s linear infinite forwards ticker-animation;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%);
  }
}

.ticker:hover p {
  -webkit-text-stroke: 1px #fff;
  color: #0000;
  transition: color .5s linear;
}

.first-part {
  animation: 20s linear infinite forwards ticker-animation;
}

.second-part {
  animation: 20s linear 10s infinite forwards ticker-animation;
}

@keyframes ticker-animation {
  0% {
    transform: translate(100%);
  }

  50% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.project-card {
  perspective: 1000px;
  cursor: pointer;
  position: relative;
}

.project-card .project-card-content, .project-card .project-card-back-content {
  transform-style: preserve-3d;
  transition: transform .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.project-card .project-card-content {
  z-index: 2;
  backface-visibility: hidden;
  transform: rotateY(0);
}

.project-card .project-card-back-content {
  z-index: 1;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.project-card.flipped .project-card-content {
  transform: rotateY(180deg);
}

.project-card.flipped .project-card-back-content {
  transform: rotateY(360deg);
}

.contact-icons li:nth-child(1) {
  opacity: 0;
  animation: 1s ease-in-out .5s forwards fadeIn;
}

.contact-icons li:nth-child(2) {
  opacity: 0;
  animation: 1s ease-in-out 1s forwards fadeIn;
}

.contact-icons li:nth-child(3) {
  opacity: 0;
  animation: 1s ease-in-out 1.5s forwards fadeIn;
}

.contact-icons li:nth-child(4) {
  opacity: 0;
  animation: 1s ease-in-out 2s forwards fadeIn;
}

.contact-icons li:nth-child(5) {
  opacity: 0;
  animation: 1s ease-in-out 2.5s forwards fadeIn;
}

.contact-icons li:nth-child(6) {
  opacity: 0;
  animation: 1s ease-in-out 3s forwards fadeIn;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.delay {
  opacity: 0;
  animation: .5s ease-in-out 2s forwards fadeIn;
}

.heading-xl {
  overflow: hidden;
}

.animated-header {
  margin: 0;
  padding: 0;
  animation: 2s forwards appearence;
  display: inline-block;
}

@keyframes appearence {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*# sourceMappingURL=index.4edad87f.css.map */
